<template>
  <div>
    <!--    <div style="margin:10px 0">-->
    <!--      <a-input placeholder="回车搜索" @keydown.enter="search" v-model="sValue"/>-->
    <!--    </div>-->
    <div style="margin: 15px 0">
      <a-input-search v-model="sValue" placeholder="回车搜索" @search="search" @keydown.enter="search"
                      style="width: 20%"/>
    </div>
    <div>
      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
          :pagination="false"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <!--        eslint-disable-next-line vue/no-unused-vars -->
        <template slot="operation" slot-scope="text, record">

          <a @click="open_editor(record.id)">编辑</a>

        </template>

      </a-table>
    </div>

    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="!isSearch"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />

    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="isSearch"
                  :default-current="1"
                  v-model="currSearch"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />


    <a-modal title="用户修改" :width="600" v-model="user_editor_status.show" @ok="user_editor_ok"
             @cancel="user_editor_cancel"
             okText="确定修改" cancelText="取消">
      <div style="margin:16px 0">
        <a-input addon-before="邮箱:" disabled="" :value="user_editor_status.user_tempObj.email"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="密码:" v-model="user_editor_status.user_tempObj.password" placeholder="更改用户密码"/>
      </div>


      <div style="margin-bottom: 16px">
        <a-input addon-before="点数:" v-model="user_editor_status.user_tempObj.score"/>
      </div>

      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          状态:
        </div>

        <a-select style="width: 90.1%" v-model="user_editor_status.user_tempObj.status">
          <a-select-option :value="0">封禁</a-select-option>
          <a-select-option :value="1">正常</a-select-option>
        </a-select>

      </div>


      <div style="margin-bottom: 16px;display: flex;justify-content: space-between;align-items: center">
        <div style="margin-left: 10px;font-size: 16px;text-align: center">
          权限:
        </div>
        <a-select style="width: 90.1%" v-model="user_editor_status.user_tempObj.user_role">
          <a-select-option :value="0">用户</a-select-option>
          <a-select-option :value="1">管理员</a-select-option>
        </a-select>
      </div>


    </a-modal>

  </div>

</template>


<script>
export default {
  name: "userList",
  data() {
    return {
      curr: 1,
      currSearch: 1,

      user_editor_status: {
        show: false,
        user_tempObj: {}
      },

      copyData: {},

      isSearch: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: "center",

        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
          align: "center",
        },

        {
          title: '付费点数',
          dataIndex: 'score',
          key: 'score',
          align: "center",

        },
        {
          title: '账号状态',
          dataIndex: 'status',
          key: 'status',
          align: "center",

        },
        {
          title: '角色权限',
          dataIndex: 'user_role',
          key: 'user_role',
          align: "center",

        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",
        },

        {
          title: '最后更新时间',
          dataIndex: 'update_time',
          key: 'update_time',
          align: "center",
        },
        {
          title: '',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          align: "center",

        },
      ],
      data: [],
      returnBack: [],
      sValue: ''
    }
  },
  methods: {
    async search() {
      this.currSearch = 1
      this.isSearch = true
      await this.$axios.post('/Admin/Api/Users/searchUser', {
        email: this.sValue,
        page: 1,
        pageCount: '12'
      }).then(val => {
        this.returnBack = JSON.parse(val.data.data)
        console.log(
            this.returnBack
        )
        this.data = this.returnBack.userList
      })
    },
    open_editor(key) {
      const temp = this.data.find(v => v.id == key)
      this.user_editor_status.user_tempObj = temp
      this.user_editor_status.user_tempObj.password = undefined
      Object.assign(this.copyData, temp)
      this.user_editor_status.show = true
    },

    user_editor_ok() {
      if (this.user_editor_status.user_tempObj.password == undefined) {
        this.user_editor_status.user_tempObj.password = '000'
      }

      this.$axios.post('/Admin/Api/Users/editUser', {
        ...this.user_editor_status.user_tempObj
      }).then(v => {
        this.$message.success(v.data.msg)
        this.user_editor_status.user_tempObj.password = undefined
      }).catch(err => {
        this.$message.success(err.msg)
        this.user_editor_cancel()
      })
      this.user_editor_status.user_tempObj = {}
      this.user_editor_status.show = false
    },

    user_editor_cancel() {
      this.$message.warning('取消修改', 1)
      const temp = this.data.find(v => v.id == this.copyData.id)
      temp.id = this.copyData.id
      temp.email = this.copyData.email
      temp.score = this.copyData.score
      temp.status = this.copyData.status
      temp.user_role = this.copyData.user_role

    }
  },
  watch: {
    curr: {
      async handler(n) {
        this.data = []
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Users/getUserList', {
          "page": n,
          "pageCount": 12
        })).data.data)
        this.returnBack.userList.forEach(v => {
          this.data.push({
            ...v,
          })
        })
      }
    },
    currSearch: {
      async handler(n) {
        this.data = []
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Users/searchUser', {
          "email": this.sValue,
          "page": n,
          "pageCount": 12
        })).data.data)
        this.isSearch = true
        this.data = this.returnBack.userList
      }
    },
  },

  async created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Users/getUserList', {
      "page": 1,
      "pageCount": 12
    })).data.data)
    this.returnBack.userList.forEach(v => {
      this.data.push({
        ...v,
      })
    })

  }
}

</script>

<style scoped>

</style>
